import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { omitBy, first, compact, min, omit, difference, isEmpty, every, values, has } from 'lodash'
import { useHasLoadingSucceeded } from 'hooks'
import { useSearch } from 'hooks/search/useSearch'
import { Form, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { PartnerCard, ExperiencesAccordion } from './components'
import { SelectInput } from 'components/inputs'
import { Autocomplete } from '@vizeat/components/es6/components/Autocomplete'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
import { formatRestrictions } from 'helpers/restrictions'
import { isInvalid, buildOptionsWithArray } from 'helpers/forms'
import { debouncedfetchAutocomplete as fetchAutocomplete, getLocalityAndCountry } from 'helpers/places'
import { createRequest, createDirectRequest, fetchDiets } from 'redux/entities/actions'
import {
  creatingRequest,
  getCreatingRequestError,
  creatingDirectRequest,
  getCreatingDirectRequestError,
  getDiets,
  getSortedTags,
  getUsersFromExperienceIds,
} from 'redux/entities/selectors'

function useRequestCreation(onSubmit) {
  const { t } = useTranslation()
  const requestCreationIsLoading = useSelector(creatingRequest)
  const requestCreationError = useSelector(getCreatingRequestError)
  const hasRequestCreationSucceeded = useHasLoadingSucceeded(requestCreationIsLoading, requestCreationError)
  const directRequestCreationIsLoading = useSelector(creatingDirectRequest)
  const directRequestCreationError = useSelector(getCreatingDirectRequestError)
  const hasDirectRequestCreationSucceeded = useHasLoadingSucceeded(
    directRequestCreationIsLoading,
    directRequestCreationError,
  )

  useEffect(() => {
    if (hasRequestCreationSucceeded || hasDirectRequestCreationSucceeded) {
      toast.success(`${t('ToastNotification::The requests have been correctly created')} 👍`, {
        type: toast.TYPE.SUCCESS,
      })
      onSubmit()
    }
  }, [hasDirectRequestCreationSucceeded, hasRequestCreationSucceeded, onSubmit, t])

  return { requestCreationIsLoading, requestCreationError }
}

function useHosts({ locality, tags, selectedHostsIds }) {
  const { eventIdsInVewport, isSearchingEvents, searchEventsError } = useSearch({ locality })
  const hostsInLocality = useSelector((state) => getUsersFromExperienceIds(state, eventIdsInVewport))

  const hostsFilteredByTags = useMemo(
    () => hostsInLocality.filter((host) => host.tags.some((tag) => tags.includes(tag))).toJS(),
    [hostsInLocality, tags],
  )
  const pickedHosts = useMemo(
    () => hostsFilteredByTags.filter(({ id }) => selectedHostsIds.includes(id)),
    [hostsFilteredByTags, selectedHostsIds],
  )
  const hostsDropdownOptions = useMemo(
    () =>
      hostsFilteredByTags.map(({ id, firstname, lastname, account }) => ({
        text: `${id} - ${firstname} ${lastname} - ${account.email}`,
        value: id,
      })),
    [hostsFilteredByTags],
  )

  return {
    pickedHosts,
    hostsDropdownOptions,
    isLoadingHosts: isSearchingEvents,
    hostsRetrievalError: searchEventsError,
  }
}

const initialState = {
  activePanelIndex: undefined,
  selectedTags: [],
  fetchedHostsIds: [],
  selectedHostsIds: [],
  requestsPayload: {},
}

export function BatchRequestForm({ guest, locality, onSubmit, partnerDemandId, requestDate }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const mDate = useMemo(() => moment.utc(requestDate), [requestDate])
  const [state, setState] = useState({ ...initialState, location: locality })

  const diets = useSelector(getDiets)
  const sortedTags = useSelector(getSortedTags)
  const tagOptions = useMemo(() => buildOptionsWithArray(sortedTags.map((t) => t.title)), [sortedTags])
  const { pickedHosts, hostsDropdownOptions, isLoadingHosts, hostsRetrievalError } = useHosts({
    locality: state.location,
    tags: state.selectedTags,
    selectedHostsIds: state.selectedHostsIds,
  })
  const { requestCreationIsLoading, requestCreationError } = useRequestCreation(onSubmit)
  const isSubmitButtonDisabled =
    requestCreationIsLoading ||
    isEmpty(state.requestsPayload) ||
    !every(values(state.requestsPayload), (payload) => has(payload, ['experience']) && has(payload, ['mDate']))

  useEffect(() => {
    if (diets.size === 0) dispatch(fetchDiets())
  }, [diets.size, dispatch])

  function handleChange(newState) {
    setState((prevState) => ({ ...prevState, ...newState }))
  }

  function handleAccordionPanel(hostId) {
    handleChange({ activePanelIndex: hostId === state.activePanelIndex ? null : hostId })
  }

  function handleHostSearchCriteriaChange({ location, selectedTags }) {
    const { fetchedHostsIds, selectedHostsIds, requestsPayload } = initialState
    handleChange({
      fetchedHostsIds,
      selectedHostsIds,
      requestsPayload,
      ...(location && { location }),
      ...(selectedTags && { selectedTags }),
    })
  }

  function handleHostChange(_, { value: selectedHostsIds }) {
    const deletedHostId = first(difference(state.selectedHostsIds, selectedHostsIds))
    const requestsPayload = omit(state.requestsPayload, [deletedHostId])
    return handleChange({ requestsPayload, selectedHostsIds })
  }

  async function handleSubmit() {
    await Promise.all(
      compact(Object.values(state.requestsPayload)).map((requestPayload) => {
        const action = requestPayload.isApprovedRequest ? createDirectRequest : createRequest
        const payload = omitBy(
          {
            event_id: requestPayload.experience.id,
            seats: min([requestPayload.seats, requestPayload.experience.max_seats]),
            user: guest,
            date: requestPayload.mDate.format('YYYY-MM-DD'),
            begins_at: requestPayload.beginsAt,
            ends_at: requestPayload.endsAt,
            price: requestPayload.price,
            occasion: requestPayload.occasion,
            private: true,
            additional_info: formatRestrictions(requestPayload.restrictions, diets),
            partner_demand_id: partnerDemandId,
            lead_id: requestPayload.leadId,
            body: requestPayload.body,
            ...(requestPayload.body && { notifyHost: true }),
            ...(requestPayload.isPrivateEvent && { privatizer_id: guest.id }),
          },
          isInvalid,
        )
        return dispatch(action(payload))
      }),
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Field width={5}>
          <label>{t('EventsCalendar::Guest (to book as)')}</label>
          <PartnerCard partner={guest} />
        </Form.Field>

        <Form.Field width={11} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Form.Field required>
            <label>{t('Users::Location')}</label>
            <Autocomplete
              placeholder={t('Users::Address, city, country (iso), postal code')}
              initialValue={state.location}
              initialItem={state.location}
              getItems={async (value) => fetchAutocomplete({ query: value, language: 'en' })}
              itemToString={(place) => getLocalityAndCountry(place) || place?.address || place?.formatted}
              onChange={({ value: location }) => handleHostSearchCriteriaChange({ location })}
              preventNavigation
              containerStyles='margin-top: 0;'
            />
          </Form.Field>

          <Form.Field required>
            <label>{t('Users::Tags')}</label>
            <SelectInput
              queryName='tags'
              placeholder={t('Users::tags')}
              multiple
              search
              options={tagOptions}
              value={state.selectedTags.join(',')}
              onChange={(selectedTags) => handleHostSearchCriteriaChange({ selectedTags })}
              preventNavigation
            />
          </Form.Field>

          <Form.Field required>
            <label>{t('EventsCalendar::Pick hosts to request')}</label>
            <Form.Dropdown
              fluid
              multiple
              search
              selection
              value={state.selectedHostsIds}
              noResultsMessage={
                isLoadingHosts
                  ? t('EventsCalendar::Loading...')
                  : t('EventsCalendar::Select a location and a tag first.')
              }
              onChange={handleHostChange}
              options={hostsDropdownOptions}
            />
          </Form.Field>
        </Form.Field>
      </Form.Group>

      <Form.Field>
        {pickedHosts.map((host) => (
          <ExperiencesAccordion
            diets={diets}
            guest={guest}
            handleAccordionPanel={handleAccordionPanel}
            handleChange={handleChange}
            host={host}
            demandDate={mDate}
            isPanelActive={state.activePanelIndex === host.id}
            key={host.id}
            requestsPayload={state.requestsPayload}
            selectedTags={state.selectedTags}
          />
        ))}
      </Form.Field>

      <Form.Field className='__btnSubmit'>
        <Button
          primary
          size='big'
          type='submit'
          disabled={isSubmitButtonDisabled}
          content={t('PartnerDemands::Summary::Create Private Event Requests').toUpperCase()}
        />
      </Form.Field>
      <ApiErrorMessage error={hostsRetrievalError || requestCreationError} modal />
    </Form>
  )
}

BatchRequestForm.propTypes = {
  guest: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    account_id: PropTypes.number,
    locality: PropTypes.string,
    country: PropTypes.string,
    phone: PropTypes.string,
    account: PropTypes.shape({
      email: PropTypes.string,
    }),
    currency: PropTypes.shape({
      id: PropTypes.number,
      iso_3: PropTypes.sring,
    }),
  }).isRequired,
  locality: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  partnerDemandId: PropTypes.number.isRequired,
  requestDate: PropTypes.string.isRequired,
}
