import { createReducer } from 'redux-immutablejs'
import { getInitialState, buildHandlers } from 'redux/entities'
import {
  accountsActions,
  adminsActions,
  availabilitiesByDateActions,
  bankAccountsActions,
  bookingsActions,
  campaignsActions,
  conversationsActions,
  eventsActions,
  filesActions,
  followupsActions,
  hostApplicationsActions,
  invitesActions,
  offlinereviewsActions,
  organizationsActions,
  partnersActions,
  partnerDemandsActions,
  payoutsActions,
  playlistsActions,
  pricingsActions,
  requestsActions,
  reviewsActions,
  searchEventsActions,
  schedulesActions,
  usersActions,

  // statics
  amenitiesActions,
  alcoholsActions,
  currenciesActions,
  dietsActions,
  foodsActions,
  languagesActions,
  tagsActions,
} from 'redux/entities/actions'

import additionalHandlers from './additionalHandlers'

const actionsCreators = {
  accountsActions,
  adminsActions,
  alcoholsActions,
  amenitiesActions,
  availabilitiesByDateActions,
  bankAccountsActions,
  bookingsActions,
  campaignsActions,
  conversationsActions,
  currenciesActions,
  dietsActions,
  eventsActions,
  filesActions,
  followupsActions,
  foodsActions,
  hostApplicationsActions,
  invitesActions,
  languagesActions,
  offlinereviewsActions,
  organizationsActions,
  partnersActions,
  partnerDemandsActions,
  payoutsActions,
  playlistsActions,
  pricingsActions,
  requestsActions,
  reviewsActions,
  searchEventsActions,
  schedulesActions,
  tagsActions,
  usersActions,
}

const initialState = getInitialState({ options: { metadata: true } })
const handlers = buildHandlers({ actionsCreators, additionalHandlers })

export default createReducer(initialState, handlers)
