import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { usePrevious } from 'hooks/usePrevious'
import { useSelector } from 'react-redux'
import { getCurrencies, getSortedTags } from 'redux/entities/selectors'
import { getLocation } from 'redux/reducers/router'
import { Form, Button } from 'semantic-ui-react'
import { SearchInput, SelectInput } from 'components/inputs'
import { buildOptionsWithArray } from 'helpers/forms'
import { konstants } from '@vizeat/helpers'

const INITIAL_STATE = {
  user: '',
  type: '',
  currency_iso_3: '',
  organizations: '',
  organization_tags: '',
  country_iso: '',
}

export function _BankAccountListSearchForm({ router }) {
  const { t } = useTranslation()

  const currencies = useSelector(getCurrencies)
  const sortedTags = useSelector(getSortedTags)
  const location = useSelector(getLocation)
  const prevLocation = usePrevious(location)

  const [state, setState] = useState(INITIAL_STATE)

  const typeOptions = useMemo(
    () => [
      { text: t('BankAccounts::Stripe'), value: konstants.BANK_ACCOUNT_TYPES.STRIPE },
      { text: t('BankAccounts::Manual'), value: konstants.BANK_ACCOUNT_TYPES.MANUAL },
    ],
    [t],
  )
  const currencyOptions = useMemo(() => buildOptionsWithArray(currencies.toList().map((c) => c.iso_3)), [currencies])
  const tagOptions = useMemo(() => buildOptionsWithArray(sortedTags.map(({ title }) => title)), [sortedTags])

  const updateStateFromLocation = useCallback(() => {
    setState({
      user: location.getIn(['query', 'user'], ''),
      type: location.getIn(['query', 'type'], ''),
      currency_iso_3: location.getIn(['query', 'currency_iso_3'], ''),
      organizations: location.getIn(['query', 'organizations'], ''),
      organization_tags: location.getIn(['query', 'organization_tags'], ''),
      country_iso: location.getIn(['query', 'country_iso'], ''),
    })
  }, [location])

  useEffect(() => {
    if (!location.equals(prevLocation)) updateStateFromLocation()
  }, [location, prevLocation, updateStateFromLocation])

  function updateQuery(query = state) {
    router.push(location.mergeIn(['query'], { ...query, offset: 0 }).toJS())
  }

  function clearFilters() {
    setState(INITIAL_STATE)
    updateQuery(INITIAL_STATE)
  }

  function clearSorting() {
    router.push(location.mergeIn(['query'], { sortBy: '', order: '' }).toJS())
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateQuery()
  }

  return (
    <Form onSubmit={handleSubmit} autoComplete='off'>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>{t('BankAccounts::User (id, name & email)')}</label>
          <SearchInput
            fluid
            icon='search'
            iconPosition='left'
            value={state.user || ''}
            onChange={(user) => setState((prevState) => ({ ...prevState, user }))}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('BankAccounts::Organization (id, name)')}</label>
          <SearchInput
            fluid
            icon='search'
            iconPosition='left'
            value={state.organizations || ''}
            onChange={(organizations) => setState((prevState) => ({ ...prevState, organizations }))}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('BankAccounts::Organization tags')}</label>
          <SelectInput
            queryName='organization_tags'
            search
            options={tagOptions}
            value={state.organization_tags || ''}
            onChange={(organizationTags) =>
              setState((prevState) => ({ ...prevState, organization_tags: organizationTags }))
            }
            preventNavigation
          />
        </Form.Field>
        <Form.Field>
          <label>{t('BankAccounts::Type')}</label>
          <SelectInput
            queryName='type'
            search
            options={typeOptions}
            value={state.type || ''}
            onChange={(type) => setState((prevState) => ({ ...prevState, type }))}
            preventNavigation
          />
        </Form.Field>
        <Form.Field>
          <label>{t('BankAccounts::Country (iso)')}</label>
          <SearchInput
            fluid
            icon='search'
            iconPosition='left'
            value={state.country_iso || ''}
            onChange={(countryIso) => setState((prevState) => ({ ...prevState, country_iso: countryIso }))}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('BankAccounts::Currency')}</label>
          <SelectInput
            queryName='currency'
            search
            options={currencyOptions}
            value={state.currency_iso_3 || ''}
            onChange={(currencyIso3) => setState((prevState) => ({ ...prevState, currency_iso_3: currencyIso3 }))}
            preventNavigation
          />
        </Form.Field>
        <Form.Field>
          <label>&nbsp;</label>
          <Button type='submit'>{t('BankAccounts::Search')}</Button>
          <Button.Group vertical basic compact size='small' style={{ transform: 'translateY(-15px)' }}>
            <Button onClick={clearFilters}>{t('BankAccounts::Clear filters')}</Button>
            <Button onClick={clearSorting}>{t('BankAccounts::Clear sorting')}</Button>
          </Button.Group>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

_BankAccountListSearchForm.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export const BankAccountListSearchForm = withRouter(_BankAccountListSearchForm)
