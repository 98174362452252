// Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { omitBy } from 'lodash'
import { useTranslation } from 'react-i18next'
// hooks
import { useHasLoadingSucceeded } from 'hooks'
// Components
import { Form, Button, Divider, Message } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import {
  DateSection,
  PricingSection,
  RestrictionSection,
  UserSection,
  OccasionSection,
  EventSection,
} from './components'
import ApiErrorMessage from 'components/errors/ApiErrorMessage'
// Helpers
import { formatRestrictions } from 'helpers/restrictions'
import { isDateTodayOrLater } from 'helpers/dates'
import { isInvalid } from 'helpers/forms'
// Redux
import { createRequest, fetchDiets, createDirectRequest } from 'redux/entities/actions'
import {
  creatingRequest,
  creatingDirectRequest,
  getCreatingRequestError,
  getCreatingDirectRequestError,
  getDiets,
  getSchedule,
} from 'redux/entities/selectors'
import './form.css'

export function RequestForm({ onSubmit, btnSubmitTitle, partnerDemandId, date, bookAs }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    host: undefined,
    experience: undefined,
    guest: bookAs,
    body: '',
    date,
    from: '19:00',
    to: '22:00',
    occasion: 'other',
    seats: 1,
    price: undefined,
    restrictions: [],
    isApprovedRequest: false,
    leadId: undefined,
  })

  const diets = useSelector(getDiets)
  const schedule = useSelector((reducerState) => getSchedule(reducerState, moment.utc(state.date)))
  const createRequestError = useSelector(getCreatingRequestError)
  const createDirectRequestError = useSelector(getCreatingDirectRequestError)
  const isCreatingRequest = useSelector(creatingRequest)
  const isCreatingDirectRequest = useSelector(creatingDirectRequest)
  const isRequestCreated = useHasLoadingSucceeded(isCreatingRequest, createRequestError)
  const isDirectRequestCreated = useHasLoadingSucceeded(isCreatingDirectRequest, createDirectRequestError)
  const canSubmit =
    state.host &&
    state.experience &&
    state.seats &&
    state.date &&
    state.price >= 0 &&
    state.guest &&
    isDateTodayOrLater(state.date)
  const isOverrideDisabled = state.date && schedule.booking_ids.size > 0
  const shouldShowPricingSection = state.experience && state.guest && state.date
  const isPriceDisabled = isOverrideDisabled || !state.isApprovedRequest

  useEffect(() => {
    if (diets.size === 0) dispatch(fetchDiets())
  }, [diets, dispatch])

  useEffect(() => {
    if (isRequestCreated || isDirectRequestCreated) {
      toast.success(
        `${t('ToastNotification::The request for {{eventTitle}} has been correctly created', {
          eventTitle: state.experience.title,
        })} 👍`,
        {
          type: toast.TYPE.SUCCESS,
        },
      )
      onSubmit()
    }
  }, [isRequestCreated, isDirectRequestCreated, onSubmit, state.experience?.title, t])

  function handleChange(data) {
    setState((prev) => ({ ...prev, ...data }))
  }

  function handleSubmit() {
    const payload = omitBy(
      {
        user: state.guest,
        event_id: state.experience.id,
        date: moment(state.date).format('YYYY-MM-DD'),
        begins_at: state.from,
        ends_at: state.to,
        price: state.price,
        seats: state.seats,
        occasion: state.occasion,
        private: true,
        additional_info: formatRestrictions(state.restrictions, diets),
        partner_demand_id: partnerDemandId,
        body: state.body,
        lead_id: state.leadId,
      },
      isInvalid,
    )
    return dispatch(state.isApprovedRequest ? createDirectRequest(payload) : createRequest(payload))
  }

  return (
    <Form onSubmit={handleSubmit}>
      <UserSection
        placeholder={t('EventsCalendar::Host name, email or id')}
        inputLabel={t('EventsCalendar::Host')}
        onUserChange={(host) => handleChange({ host })}
        isHost
        user={state.host}
      />

      <EventSection
        host={state.host}
        isDisabled={!state.host}
        onEventChange={(experience) => handleChange({ experience, from: experience.begins_at, to: experience.ends_at })}
      />

      <UserSection
        placeholder={t('EventsCalendar::Guest name, email or id')}
        inputLabel={t('EventsCalendar::Guest (to book as)')}
        onUserChange={(guest) => handleChange({ guest })}
        isDisabled={!state.experience}
        user={state.guest}
      />

      <Divider />

      <DateSection
        date={state.date}
        handleDateChange={(date) => handleChange({ date })}
        handleChange={({ from = state.from, to = state.to }) => handleChange({ from, to })}
        isDisabled={!state.experience}
        isTimeDisabled={isOverrideDisabled}
        eventId={state.experience?.id}
        from={state.from}
        to={state.to}
      />

      <Divider />

      <Form.Group className='__item'>
        <OccasionSection
          inputWitdh={16}
          occasion={state.occasion}
          handleChange={({ occasion }) => handleChange({ occasion })}
          isDisabled={!state.experience}
        />
      </Form.Group>

      <Divider />

      <Form.Field>
        <label>{t('EventsCalendar::Lead id')}</label>
        <Form.Input
          type='number'
          min={1}
          placeholder={t('EventsCalendar::Lead id')}
          value={state.leadId}
          onChange={(_, { value }) => handleChange({ leadId: parseInt(value, 10) })}
        />
      </Form.Field>

      <Divider />

      <Form.Field>
        <label>{t('EventsCalendar::Seats')}</label>
        <Form.Input
          type='number'
          min={1}
          placeholder={t('EventsCalendar::How many guests?')}
          value={state.seats}
          onChange={(_, { value }) => handleChange({ seats: parseInt(value, 10) })}
        />
      </Form.Field>

      <Divider />

      {shouldShowPricingSection ? (
        <PricingSection
          currency={state.guest.currency}
          date={state.date}
          event={state.experience}
          handleChange={({ price }) => handleChange({ price })}
          isPriceDisabled={isPriceDisabled}
          price={state.price}
          seats={state.seats}
          user={state.guest}
        />
      ) : (
        <Message info style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Message.Header>
            {t(
              'EventsCalendar::You will be able to set the price for auto approved requests once the following fields have been filled in:',
            )}
          </Message.Header>
          <Message.List>
            <Message.Item>{t('EventsCalendar::Event')}</Message.Item>
            <Message.Item>{t('EventsCalendar::Guest (to book as)')}</Message.Item>
            <Message.Item>{t('EventsCalendar::Date')}</Message.Item>
          </Message.List>
        </Message>
      )}

      <Divider />

      {state.seats > state.experience?.max_seats && (
        <Message
          info
          icon='info circle'
          content={t('EventsCalendar::The number of seats is over the event capacity')}
        />
      )}
      {state.price < 0 && <Message negative>{t('EventsCalendar::Price should not be negative')}</Message>}

      <RestrictionSection
        restrictions={state.restrictions}
        diets={diets}
        isDisabled={!state.experience}
        handleChange={({ restrictions }) => handleChange({ restrictions })}
      />

      <Divider />

      <Form.Field className='__item'>
        <label>{t('EventsCalendar::Message to the host (optional):')}</label>
        <Form.TextArea
          name='body'
          placeholder={t('EventsCalendar::Write a message')}
          disabled={!state.experience}
          rows={2}
          onChange={(_, input) => handleChange({ body: input.value })}
        />
      </Form.Field>

      <Form.Field>
        <Form.Radio
          label={t('EventsCalendar::Create approved request')}
          toggle
          checked={state.isApprovedRequest}
          onChange={(_, { checked }) => handleChange({ isApprovedRequest: checked })}
          style={{ marginBottom: 32 }}
        />
      </Form.Field>

      <Form.Field className='__btnSubmit'>
        <Button primary size='big' type='submit' disabled={!canSubmit} content={btnSubmitTitle.toUpperCase()} />
      </Form.Field>

      <ApiErrorMessage error={createRequestError || createDirectRequestError} modal />
    </Form>
  )
}

RequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  btnSubmitTitle: PropTypes.string.isRequired,
  partnerDemandId: PropTypes.number,
  date: PropTypes.moment,
  bookAs: PropTypes.immutable.record,
}

RequestForm.defaultProps = {
  partnerDemandId: undefined,
  date: undefined,
  bookAs: undefined,
}
